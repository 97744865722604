import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { Iinfo,Iservice } from '../../types/interface'
import { colors } from '../../colors/colors'

interface Iprops {
    info: Iinfo
    fetchlink: string
}
const Service = (props:Iprops) => {
    const { info, fetchlink } = props
    const location = useLocation()
    const mobile = useMediaQuery('(max-width:690px)');
    const navigate = useNavigate()


    const [ data, setData ] = useState<Iservice>({} as Iservice)
    const [ video, setVideo ] = useState<string>("")

    useEffect(() => {
        fetch(fetchlink)
        .then(res => res.json())
        .then(res => {
            const result = res?.[location?.pathname]
            setData(result)
            setVideo(result?.video?.url)

        })
    
      return () => {
        
      }
    }, [location])
    

    return (
        <Box component="section" sx={{minHeight:"100vh",pt:"5rem",width:"100vw",mb:"5rem",justifyContent:"flex-start"}} className='flex__center-c' >

            {/* Main title & subtitle  #75d4f933 */}
            <Box sx={{mb:"2rem",backgroundColor:colors?.bg?.light,height:"15rem",boxShadow:"rgba(149, 157, 165, 0.2) 0px 8px 24px"}} width="100%" className="flex__center-c" >

                    <Box maxWidth="xl" width="75%" >
                        <Typography variant='h1' textAlign={"left"} maxWidth="xl" ml="0.5rem" sx={{fontSize:"3rem !important"}}>
                            {data?.title}
                        </Typography>
                        <Typography variant='subtitle1' textAlign={"left"} maxWidth="xl" ml="0.5rem">
                            {data?.subTitle}
                        </Typography>                        
                    </Box>

            </Box>

            {/* h1,h2,h3,p,img.... osv */}
            <Box component="main" maxWidth="xl" width="75%">
                {data?.info?.map((item,i) => {
                    
                    if(item?.key === "img"){                
                        return(
                            <img style={{objectFit:"contain",width: mobile ? "100%" : "50%"}} src={item?.data} alt='' key={`service${item?.key}${i}`} />
                        )
                    }else if( ["h1","h2","h3","h4","h5","h6","subtitle1"].includes(item?.key) ){
                        type Ikeys = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1"
                        return(
                            <Typography variant={item?.key as Ikeys} key={`service${item?.key}${i}`} sx={{mb:"1rem"}} textAlign={"left"}>
                                {item?.data}
                            </Typography>
                        )
                    }else if( ["li"].includes(item?.key) ){                    
                        return(
                            <Typography variant={"subtitle1"} key={`service${item?.key}${i}`}  textAlign={"left"}>
                                {item?.data}
                            </Typography>
                        )
                    }
                    else{
                        return null
                    }

                    
                })}

                <Button variant='outlined' sx={{mt:"0.5rem"}} color='primary' onClick={()=>navigate("/contact")}>
                   {info?.Service?.Button_Contact}
                </Button>
                
            </Box>

                {video && 
                <Box sx={{width:"100%",overflow:"hidden",paddingTop:"56.25%",position:"relative",maxWidth:"xl",mt:"5rem",display: video ? "initial" : "none"}}>
                    <iframe
                        style={{width:"100%",height:"100%",top:0,bottom:0,left:0,right:0,position:"absolute",display: video ? "initial" : "none", maxHeight:"95vh"}}
                        width="98%"
                        height="100%"
                        src={video}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    />
                </Box>  
                }
        </Box>
    )
}

export default Service