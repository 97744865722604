import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@mui/material/Typography'


import { HandleLoadingImages } from '../../Functions/HandleLoadingImages'
import { MainLoading } from '../../components'
import { Iinfo } from '../../types/interface'



const imageStyle ={
    text:{color:"#ffffffff",transition:"color 0.5s ease-in-out",maxWidth:"95%"},
    img: { 
        transition:"transform 1.5s ease-in-out",
        objectFit:"cover" as any,
        height:"100%",
        width:"100%",
        position:"absolute" as any,
        zIndex:-1 
    },
    box:{
        position:"relative",
        backgroundColor:"#0000004e",
        transition:"background-color 0.5s ease-in-out",
        overflow:"hidden",        
        cursor:"pointer",
        ":hover":{
            "backgroundColor": "#00000082",            
        },
        ":hover img":{          
            "transform": "scale3d(1.1,1.1,1.1)"
        },
        ":hover h3":{
            "color": "#ffffffff"
        }
    }
}
interface Iprops {
    info:Iinfo
}
const Welcomepage = (props:Iprops) => {
    const { info } = props

    const image_ref = useRef(0)
    const navigate = useNavigate()
    
    const [ loading, setLoading ] = useState(true)
    interface Iwelcomeimage {
        image:string
        title:string
        link:string
        objectPosition: string
    }
    const [ images, setImages ] = useState<Iwelcomeimage []>(info?.Welcomepage?.Images)
    const titles = [info?.Welcomepage?.Title1, info?.Welcomepage?.Title2, info?.Welcomepage?.Title3];
    const titleIndex = useRef(0);
    const titleRef:any = useRef(null);

    useEffect(() => {
        
        setImages(info?.Welcomepage?.Images)
    
        return () => {
      
        }
    }, [info])

    useEffect(() => {
        const interval = setInterval(() => {
            titleIndex.current = (titleIndex.current + 1) % titles.length;
            const currentTitle = titles[titleIndex.current];

            if (titleRef.current) {
                titleRef.current.classList.remove('animated');
                // Force reflow to restart the animation
                void titleRef.current.offsetWidth;
                titleRef.current.classList.add('animated');
                titleRef.current.textContent = currentTitle;
            }
        }, 3000);

        return () => clearInterval(interval); // Clean up the interval on component unmount
    }, [titles]);
    

    const mobile = useMediaQuery('(max-width:690px)');
   
    interface Iimagecomponent {
        image: string
        title:string
        link:string
        objectPosition:string
    }
    const SingleImage = (imagecomponent:Iimagecomponent) => {
        const { image, title, link, objectPosition } = imagecomponent
        let imgobj:any = structuredClone(imageStyle.img ) 
        imgobj["objectPosition"] = objectPosition

        return(
            <Box width={"100%"} height={mobile ? "30vh" : "50vh" } sx={imageStyle?.box} className="flex__center-c"  onClick={()=>navigate(link)}>
                <Typography variant='h2'  sx={imageStyle?.text}>
                    {title}
                </Typography>
                <img src={image} style={imgobj} alt='' onLoad={()=>HandleLoadingImages(images.length, image_ref, setLoading)}/>
            </Box>
        )
    }
    const DoubleImage = (imagecomponent:Iimagecomponent) => {
        const { image, title, link, objectPosition } = imagecomponent
        let imgobj:any = structuredClone(imageStyle.img ) 
        imgobj["objectPosition"] = objectPosition
        return(
            <Box width={"50%"} height={mobile ? "30vh" : "50vh" } sx={imageStyle?.box} className="flex__center-c"  onClick={()=>navigate(link)}>
                <Typography variant='h2' sx={imageStyle?.text}>
                    {title}
                </Typography>
                <img src={image} style={imgobj} alt='' onLoad={()=>HandleLoadingImages(images.length, image_ref, setLoading)}/>
                
            </Box>
        )
    }

    return (
        <Box component="section" className="flex" sx={{minHeight:"100vh",width:"100%",display:"flex",flexWrap:"wrap"}}>

            <Box sx={{width:"100vw",height:"100vh"}} className="flex__center-r">
                <img className="header-img-noblur" src={info?.Welcomepage?.Image} alt=''/>
                <Box className="flex__center-c"  bgcolor="#00000098" sx={{p:"2rem"}}>
                    {/* <Typography variant='h3' color="primary.main" mb="1rem" textAlign={"left"} >
                        {info?.Welcomepage?.SubTitle}
                    </Typography> */}
                    {/* <TitleLogo height={6} fill={theme.palette.primary.main}/> */}
                    <Typography  variant='h2' color="primary.contrastText"  sx={{m:"1.5rem 0"}}>
                        {info?.Welcomepage?.Title0}
                    </Typography>

                    <Box >
                        <Typography ref={titleRef} className="animated" variant='h2' color="primary.contrastText" sx={{ m: "1.5rem 0" }}>
                            {titles[0]}
                        </Typography>
                    </Box>

            

                    <Typography variant='subtitle1' color="primary.contrastText" m="1rem 0" >
                        {info?.Welcomepage?.Info}
                    </Typography>
                    {/* <Button variant="contained" color='primary' onClick={()=>navigate("/contact")}>
                        {info?.Welcomepage?.Button}
                    </Button> */}

                </Box>
            </Box>

            {images?.map((item,i) => {                
                if(i % 3 === 0 || mobile || i === images.length - 1){
                    return(
                        <SingleImage image={item?.image} title={item?.title} link={item?.link} objectPosition={item?.objectPosition} key={`singleimg${item}${i}`} />
                    )
                }else{
                    return(
                        <DoubleImage image={item?.image} title={item?.title} link={item?.link} objectPosition={item?.objectPosition} key={`doubleimg${item}${i}`}/>
                    )
                }                
            })}



            {/* LOADING SCREEN */}
            {loading && 
                <MainLoading loading={loading} info={info}  />
            }

        </Box>
    )
}

export default Welcomepage